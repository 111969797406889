const state = {
  popup: {
    visible: false,
    title: '',
    href: 'https://docs.bizflycloud.vn',
  },
};

const getters = {
  popup(state) {
    return state.popup;
  },
};

const mutations = {
  UPDATE(state, payload) {
    state.popup = payload;
  },
};

const actions = {
  update({ commit }, payload) {
    commit('UPDATE', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
