export const DASHBOARD = process.env.VUE_APP_DASHBOARD_URL || '/dashboard';

export const DOCS = {
  TERMS: 'https://docs.bizflycloud.vn/platform_overview/dieu_khoan_su_dung',
  SLA: 'https://docs.bizflycloud.vn/platform_overview/sla',
  PRIVACY: 'https://docs.bizflycloud.vn/platform_overview/chinh_sach_bao_mat',
};

export const HELPER = {
  HOMEPAGE: 'https://bizflycloud.vn',
  HOTLINE: '02473028888',
  EMAIL: 'support@bizflycloud.vn',
};

export const IMG = {
  staging: 'https://staging-id.bizflycloud.vn',
};
