const baseBackendUrl = process?.env?.VUE_APP_BACKEND_AUTH_URL;

export default {
  AUTH_URL: baseBackendUrl,
  REGISTER: `${baseBackendUrl}/register`,
  LOGIN: `${baseBackendUrl}/login`,
  LOGOUT: `${baseBackendUrl}/logout`,
  VERIFICATION: `${baseBackendUrl}/verification`,
  USERS: `${baseBackendUrl}/users`,
  OTP: `${baseBackendUrl}/otp`,
  BANNERS: `${baseBackendUrl}/banners`,
};
