const state = {
  isAuthenticated: false,
  callbackUrl: '',
  callbackPrefix: '',
  ticket: '',
  twebmail: '',
  tprarams: '',
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  callbackUrl(state) {
    return state.callbackUrl;
  },
  callbackPrefix(state) {
    return state.callbackPrefix;
  },
  ticket(state) {
    return state.ticket;
  },
  twebmail(state) {
    return state.twebmail;
  },
  tprarams(state) {
    return state.tprarams;
  },
};

const mutations = {
  UPDATE_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  UPDATE_CALLBACK_URL(state, payload) {
    state.callbackUrl = payload;
  },
  UPDATE_CALLBACK_PREFIX(state, payload) {
    state.callbackPrefix = payload;
  },
  UPDATE_TICKET(state, payload) {
    state.ticket = payload;
  },
  UPDATE_TWEBMAIL(state, payload) {
    state.twebmail = payload;
  },
  UPDATE_T_PARAMS(state, payload) {
    state.tprarams = payload;
  },
};

const actions = {
  updateAuthenticated({ commit }, payload) {
    commit('UPDATE_AUTHENTICATED', payload);
  },
  updateCallbackUrl({ commit }, payload) {
    commit('UPDATE_CALLBACK_URL', payload);
  },
  updateCallbacPrefix({ commit }, payload) {
    commit('UPDATE_CALLBACK_PREFIX', payload);
  },
  updateTicket({ commit }, payload) {
    commit('UPDATE_TICKET', payload);
  },
  updateTwebmail({ commit }, payload) {
    commit('UPDATE_TWEBMAIL', payload);
  },
  updateTParam({ commit }, payload) {
    commit('UPDATE_T_PARAMS', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
