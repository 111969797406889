import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: () => import('@/layout/Layout'),
    children: [
      {
        path: 'register',
        name: 'RegisterView',
        component: () => import('@/views/SignIn/index.vue'),
        meta: { title: 'title.register' },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login/index.vue'),
        meta: { title: 'title.login' },
      },
      // Forgot pass
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword/index.vue'),
        meta: { title: 'title.forgotPassword' },
      },
      // End forgot pass
      // Logout
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'common.logout' },
      },
      // End Logout
      {
        path: '*',
        redirect: { path: '/login' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
