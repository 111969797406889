<template>
  <div class="loading">
    <img class="logo-loading" :src="loadingImage" alt="loading">
  </div>
</template>

<script>
import loadingImage from '@/assets/images/loading.gif';

export default {
  name: 'LoaderContainer',
  data() {
    return {
      loadingImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fffffff7;
    backdrop-filter: blur(25px);
    z-index: 999999;
}
.logo-loading {
  width: 150px;
  max-width: 90%;
}
</style>
