import { configRequest as request } from '@/config/ConfigRequest';
import ENDPOINT from '@/config/endpoint';
import { getToken } from '@/utils/common';

const cookieTempTokenKey = process.env.VUE_APP_COOKIE_TEMPORARY_TOKEN_KEY || 'tmp_token';

function userInfo(params) {
  const token = getToken();
  return request({
    url: `${ENDPOINT.USERS}/info`,
    method: 'get',
    params,
  }, token);
}

function forgotPassword(data) {
  return request({
    url: `${ENDPOINT.USERS}/forgot`,
    method: 'post',
    data,
  });
}

function verifyForgotCode(data) {
  const token = getToken(cookieTempTokenKey);
  return request({
    url: `${ENDPOINT.USERS}/forgot/verify`,
    method: 'post',
    data,
  }, token);
}

function resetPassword(data) {
  const token = getToken(cookieTempTokenKey);
  return request({
    url: `${ENDPOINT.USERS}/forgot/reset`,
    method: 'post',
    data,
  }, token);
}

function updatePassword(data) {
  const token = getToken();
  return request({
    url: `${ENDPOINT.USERS}/update`,
    method: 'patch',
    data,
  }, token);
}

export default {
  userInfo,
  forgotPassword,
  resetPassword,
  verifyForgotCode,
  updatePassword,
};
