import { Notification } from '../assets/js/index.min.js';
import i18n from './i18n';
import { checkType } from './common';
import CONSTANTS from '../config/constants';

const { DISABLE_REQUESTS_ACTION } = CONSTANTS;

const checkCondition = ({
  requestUrl,
  status,
  condition,
}) => {
  const findKey = Object.keys(DISABLE_REQUESTS_ACTION)
    .find((request) => requestUrl.includes(request));
  if (findKey) {
    const requestConditions = DISABLE_REQUESTS_ACTION?.[findKey];
    if (requestConditions?.length) {
      if (requestConditions?.[0]?.status === 'all') return false;
      const currentRequestCondition = requestConditions.find(
        (condition) => condition?.status === status,
      );
      if (currentRequestCondition?.[condition] === false) return false;
    }
  }
  return true;
};

const handleNotify = ({
  requestUrl,
  status,
  message,
  type,
  title,
}) => {
  if (!checkCondition({ requestUrl, status, condition: 'notify' })) return;
  Notification({
    title, message, type, customClass: type,
  });
};

export function handleResponseErrorMessage(err) {
  const requestUrl = err?.response?.config?.url;
  const title = i18n.t('notification.error.title');
  const message = checkType(err?.response?.data?.detail)
    || checkType(err?.response?.data?.detail[0]?.msg);
  const type = 'error';

  handleNotify({
    requestUrl,
    status: err?.response?.status,
    message,
    type,
    title,
  });
  return err;
}
