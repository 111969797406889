<template>
  <div id="app">
    <Loader v-if="isAuthenticated || isLoading"></Loader>
    <router-view />
    <doc-frame v-if="popup.visible" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import userServices from '@/services/user';
import Loader from '@/layout/Loader.vue';
import { DASHBOARD } from '@/config/urls';
import { addSearchParams } from '@/utils/common';
import getPageTitle from '@/utils/getPageTitle';

const DocFrame = () => import('@/components/commons/DocFrame.vue');
export default {
  components: {
    Loader,
    DocFrame,
  },
  data() {
    return {
      isLoading: false,
      dashboardUrl: DASHBOARD,
      clearUpdateResize: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['callbackUrl', 'ticket', 'twebmail', 'tprarams', 'isAuthenticated']),
    ...mapGetters('docframe', ['popup']),
  },
  async beforeMount() {
    this.isLoading = true;
    await new Promise((r) => setTimeout(r, 300)); // Wait midleware call
    const isLogout = localStorage.getItem('authenticated');
    try {
      const payload = {
        service: this.callbackUrl || '',
      };
      if (this.tprarams === 'webmail') {
        payload.t = 'webmail';
      }
      let isAuthenticated = false;
      const userRes = await userServices.userInfo(payload);
      isAuthenticated = userRes?.status_code === 200;
      // Set ticket
      if (userRes?.data?.ticket) {
        this.updateTicket(userRes?.data?.ticket);
      }
      if (userRes?.data?.login_param) {
        this.updateTwebmail(userRes?.data?.login_param);
      }
      this.updateAuthenticated(isAuthenticated);
      if (isAuthenticated && isLogout !== 'logout') {
        const urlRedirect = this.callbackUrl || this.dashboardUrl;
        let nextUrl = addSearchParams(urlRedirect, { ticket: this.ticket });
        nextUrl = this.twebmail
        ? addSearchParams(nextUrl, { login_param: this.twebmail })
        : nextUrl;
        window.location.href = nextUrl.href;
      }
      localStorage.setItem('authenticated', '');
    } catch (error) {
      // Don't through error
    } finally {
      await new Promise((r) => setTimeout(r, 300)); // Wait transition DOM, style, gif... in 300 ms
      this.isLoading = false;
    }
  },
  mounted() {
    this.updateDeviceWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      clearTimeout(this.clearUpdateResize);
      this.clearUpdateResize = setTimeout(() => {
        this.updateDeviceWidth(window.innerWidth);
      }, 100);
    });
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.changeLanguage(newLocale);
      },
    );
  },
  destroyed() {
    clearTimeout(this.clearUpdateResize);
  },
  methods: {
    ...mapActions('auth', ['updateAuthenticated', 'updateTicket', 'updateTwebmail']),
    ...mapActions('device', ['updateDeviceWidth']),
    async changeLanguage(lang) {
      // setDocumentLang
      document.documentElement.lang = lang;
      // setDocumentTitle
      const title = getPageTitle(this.$route?.meta?.title);
      document.title = title;
    },
  },
};
</script>
