import axios from 'axios';
import i18n from './i18n';
import { handleResponseErrorMessage } from './response';

const service = axios.create({
  timeout: 30000, // request timeout
  withCredentials: true,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    /* eslint-disable */
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
      'Content-Language': i18n.locale || process.env.VUE_APP_I18N_LOCALE,
    };
    /* eslint-enable */
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // Add logic to check response here
    return res;
  },
  (error) => {
    return Promise.reject(handleResponseErrorMessage(error));
  },
);

export default service;
