import Cookies from 'js-cookie';
import CONSTANTS from '@/config/constants';
import store from '@/store';

const { PREFIX_CALLBACKS, CRM_COOKIE } = CONSTANTS;

const accessTokenKey = process.env.VUE_APP_COOKIE_ACCESS_TOKEN_KEY || 'rf-tk';

export function getToken(key = accessTokenKey) {
  return Cookies.get(key);
}

export function setToken(token, key = accessTokenKey) {
  return Cookies.set(key, token);
}

export function removeToken(key = accessTokenKey) {
  return Cookies.remove(key);
}

export function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

export function checkType(message, type = 'string') {
  if (typeOf(message) === type) return message;
  return false;
}

export function getCookie(cname) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${cname.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getCallbackUrl(to, callback) {
  const { query } = to;
  let callbackUrl = '';
  Object.keys(query).every((queryKey) => {
    if (PREFIX_CALLBACKS.indexOf(queryKey) !== -1) {
      const prefix = queryKey;
      callbackUrl = query[queryKey];
      // Check it is url, if not prototype url -> return ''
      try {
        callbackUrl = new URL(callbackUrl);
        if (callback && typeof callback === 'function') {
          callback(prefix);
        }
      } catch (error) {
        callbackUrl = '';
      }
    }
    return true;
  });
  return callbackUrl;
}

export function getTParam(to, callback) {
  const { query } = to;
  let callbackUrl = '';
  Object.keys(query).every((queryKey) => {
    if (queryKey === '_t') {
      callbackUrl = query[queryKey];
      if (callback && typeof callback === 'function') {
        callback(callbackUrl);
      }
    }
    return true;
  });
  return callbackUrl;
}

export function setUtmCookies() {
  let utmData = '';
  const inputCookies = document.querySelector('input[name="_cookies"]');
  if (inputCookies && inputCookies.value) {
    try {
      utmData = inputCookies.value;
    } catch (error) {
      utmData = [];
    }
  }
  return Cookies.set(CRM_COOKIE, utmData);
}

export function getUtmCookies() {
  return Cookies.get(CRM_COOKIE);
}

export function validateEmailLength(rule, value, callback) {
  if (value?.length > 191) {
    callback(new Error());
  } else if (value.indexOf('@') !== -1) {
    const mailName = value.split('@')[0];
    if (mailName.length > 64) {
      callback(new Error());
    } else { callback(); }
  } else {
    callback();
  }
}
/**
 * It takes a URL string and an object of query parameters,
 * and returns a new URL object with the query
 * parameters added
 * @param urlString - The URL to add the parameters to.
 * @param [params] - An object of key-value pairs to add to the URL.
 * @returns A new URL object with the search params added.
 */
export function addSearchParams(urlString, params = {}) {
  try {
    const url = new URL(urlString);
    return new URL(
      `${url.origin}${url.pathname}?${new URLSearchParams([
        ...Array.from(url.searchParams.entries()),
        ...Object.entries(params),
      ])}`,
    );
  } catch (error) {
    return urlString;
  }
}

export function showDocFrame(inputUrl, text = '') {
  const arrayDomainList = ['docs.bizflycloud.vn'];
  const url = inputUrl;
  if (arrayDomainList.some((domain) => url.includes(domain))) {
    const payload = {
      visible: true,
      title: text,
      href: addSearchParams(url, { embedded: 'true' })?.href, // add '?embedded=true' to url
    };
    store.dispatch('docframe/update', payload);
  } else {
    window.open(url, '_blank');
  }
}

/**
 * Google Analytic Tracking
 * @param {Object} data : ga ccontent
 * @param {String} email : user email enter
 */
export function googleTracking(data, email = '') {
  const ga = {
    cid: email,
    cm: 'web',
    cn: '',
    cs: 'auth_v2',
    ea: 'Click',
    ec: '',
    el: '',
    ev: 1,
    t: 'event',
    tid: 'UA-62110417-4',
    uid: email,
    v: 1,
    ...data,
  };
  const queryString = new URLSearchParams(ga).toString();
  const url = `https://www.google-analytics.com/collect?${queryString}`;
  const image = document.createElement('img');
  image.style.width = '0px';
  image.style.height = '0px';
  image.style.display = 'none';
  image.src = url;
  image.id = `image-analytics-${new Date().getTime()}`;
  document.getElementsByTagName('body')[0].appendChild(image);
  image.remove();
}
