import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getCookie } from '@/utils/common.js';

Vue.use(VueI18n);

const locales = require.context('../lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
const messages = {};
locales.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
    const locale = matched[1];
    messages[locale] = locales(key);
  }
});

const i18n = new VueI18n({
  locale: getCookie('bizfly-language') ? getCookie('bizfly-language') : process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
  messages,
});

const setI18nLocaleInServices = ((locale) => {
  document.querySelector('html').setAttribute('lang', locale);
  return locale;
})(i18n.locale);

export default i18n;
