import i18n from '@/utils/i18n';

const title = process.env.VUE_APP_BASE_TITLE || 'Bizfly Cloud';

export default function getPageTitle(key) {
  const hasKey = i18n.te(key);
  if (hasKey) {
    const pageName = i18n.t(key);
    return `${pageName} - ${title}`;
  }
  return `${title}`;
}
