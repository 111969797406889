import { getCallbackUrl, getTParam } from '@/utils/common';
import router from '@/router/index';
import store from '@/store';
import logout from '@/services/logout';
import getPageTitle from '@/utils/getPageTitle';

router.beforeEach(async (to, from, next) => {
  const title = getPageTitle(to?.meta?.title);
  document.title = title;

  /** If 'login' or 'register' router, get callback url to redirect
   * 1. Case login or register with query url => get url
   * 2. Case change router to login or register:
   *  2.1 If query has url do Case 1
   *  2.2 Else query null check callback in store.auth.callbackUrl
   */
   if (['/login', '/register', '/logout'].includes(to?.path)) {
    const url = getCallbackUrl(to, (key) => {
      store.dispatch('auth/updateCallbacPrefix', key);
    });
    const nextCallbackUrl = url?.href || store.getters?.['auth/callbackUrl'];
    store.dispatch('auth/updateCallbackUrl', nextCallbackUrl);
    // get _t params
    getTParam(to, (key) => {
      store.dispatch('auth/updateTParam', key);
    });
    /** Set UTM CRM TRACKING data to cookie */
    const { setUtmCookies } = await import('@/utils/common');
    setUtmCookies();
  }

  // Logout : when user request router /logout
  if (to?.path === '/logout') {
    localStorage.setItem('authenticated', 'logout');
    const { removeToken } = await import('@/utils/common');
    const destination = {
      path: '/login',
      query: to?.query,
      params: to?.params,
    };
    // Delete Token and call api logout
    removeToken();
    const isLogout = await logout();
    next(destination);
  // End logout
  } else {
    localStorage.setItem('authenticated', false);
    next();
  }
});
