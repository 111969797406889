export function lowerCase(text) {
  return text.toLowerCase();
}
/**
 * Upper case first char
 */
 export function uppercaseFirst(string) {
  const firstChar = string?.charAt(0)?.toUpperCase();
  const endString = string?.toLowerCase()?.slice(1);
  return `${ firstChar }${ endString }`;
}
