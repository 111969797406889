import request from '@/utils/request';
import ENDPOINT from '@/config/endpoint';

function logout() {
  return request({
    url: ENDPOINT.LOGOUT,
    method: 'post',
  });
}

export default logout;
