import request from '@/utils/request';

export const configRequest = (args, token) => request({
  ...args,
  headers: {
    ...(token && { Authorization: `Bearer ${token}` }),
  },
});

export const requestRecaptcha = (args, recaptchaToken) => request({
  ...args,
  headers: {
    ...(recaptchaToken && {
      'X-Captcha-Token': recaptchaToken,
    }),
  },
});
